<template>
  <div id="scrollable" style="background: black">
    <v-row
      class="py-4"
      id="scrollable"
      no-gutters
      :style="styles"
      align="center"
      justify="center"
    >
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
      <v-col style="min-width: 50%">
        <v-img
          v-if="!noheader"
          :src="getImages(item.slug).header"
          contain
          class="my-12"
          @error="noheader = true"
          eager
        ></v-img>
        <v-card-text
          v-else
          style="user-select: none"
          :style="{
            'font-size: 5rem !important': !isMobileDevice(),
          }"
          class="text-center text-uppercase py-12"
          :class="{
            'text-h1': !$vuetify.breakpoint.mobile,
            'text-h3': $vuetify.breakpoint.mobile,
            'white--text': item.name,
            'grey--text': data.name && !item.name,
          }"
        >
          {{ item.name || data.name }}
        </v-card-text>
        <v-card
          color="black"
          style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
          elevation="12"
        >
          <v-btn
            @click.prevent="item.meta.show = !item.meta.show"
            block
            tile
            :class="{
              error: item.meta.show,
              success: !item.meta.show,
            }"
            >{{ item.meta.show ? "Hide" : "Show" }}
            {{ item.name || data.name }}
          </v-btn>
          <v-row no-gutters>
            <v-col>
              <v-btn block tile color="info">Save changes</v-btn>
            </v-col>
            <v-col>
              <v-btn :ripple="false" block tile @click.prevent="resetChanges()"
                >Cancel changes</v-btn
              >
            </v-col>
          </v-row>
          <v-row class="py-4" align="center" justify="center">
            <v-col cols="auto">
              <v-btn
                disabled
                outlined
                height="48px"
                width="auto"
                class="px-8"
                :href="item.meta.svc_url"
                target="_blank"
                rel="nofollow author external"
              >
                <v-icon class="bi-github pr-2" left size="26px"></v-icon>
                View project on GitHub
              </v-btn>
            </v-col>
          </v-row>

          <!-- About -->
          <v-card-text class="text-h3 white--text pb-0"
            >About
            <v-text-field
              v-model="item.name"
              :placeholder="data.name"
              class="text-h4"
              solo
              style="display: inline-block"
            ></v-text-field
          ></v-card-text>
          <vue-easymde
            class="mx-4"
            :highlight="true"
            preview-class="markdown-body"
            v-model="item.body.md"
            ref="editor"
          ></vue-easymde>

          <div>
            <!-- Screenshots -->
            <v-card-text class="text-h3 white--text">Screenshots</v-card-text>
            <v-row no-gutters class="px-4" v-if="item.screenshots.length != 0">
              <v-col
                v-for="n in 9"
                :key="`${item.slug}_screenshot_${n}`"
                class="d-flex child-flex"
                cols="4"
              >
                <v-img
                  v-if="n - 1 < item.screenshots.length"
                  :key="`${item.slug}_screenshot-${n - 1}`"
                  :src="`https://cdn.bitheral.net/projects/${
                    item.slug
                  }/screenshots/${item.slug}-${n - 1}.png`"
                  :lazy-src="`https://cdn.bitheral.net/projects/${
                    item.slug
                  }/screenshots/${item.slug}-${n - 1}.png`"
                  aspect-ratio="1"
                  height="100%"
                ></v-img>
                <v-card @click.prevent="" tile height="233.417" v-else>
                  <v-row class="fill-height" align="center" justify="center">
                    <v-card-text class="text-center">
                      <v-icon size="64px">bi-plus</v-icon>
                    </v-card-text>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              no-gutters
              class="px-4"
              v-else-if="
                item.screenshots == null || item.screenshots.length == 0
              "
            >
              <v-col v-for="n in 9" :key="n" class="d-flex child-flex" cols="4">
                <v-card height="150">
                  <v-card-text class="text-center">
                    <v-icon size="64px">bi-image</v-icon>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <!-- Credits -->
          <div>
            <v-card-text class="text-h3 white--text pb-0"
              >Collaborators</v-card-text
            >
            <div v-if="item.collaborators.length != 0">
              <v-list-item
                class="mx-1"
                two-line
                v-for="contributor in item.collaborators"
                :key="`${
                  item.slug
                }_contributor-${contributor.name.toLowerCase()}`"
              >
                <v-list-item-content>
                  <v-text-field :value="contributor.name"></v-text-field>
                  <v-chip-group column>
                    <v-chip v-for="role in contributor.roles" :key="role">
                      {{ role }}
                    </v-chip>
                    <v-text-field
                      :placeholder="`Add a role for ${contributor.name}`"
                    ></v-text-field>
                  </v-chip-group>
                </v-list-item-content>
              </v-list-item>
            </div>
            <div class="mx-4" v-else>
              <v-btn> Add a collaborator </v-btn>
            </div>
          </div>

          <!-- Downloads -->
          <div v-if="item.downloads.length != 0 && !isMobileDevice()">
            <v-card-text class="text-h3 white--text pb-0"
              >Downloads</v-card-text
            >
            <div
              v-for="(download, download_index) in item.downloads
                .filter((dl) =>
                  dl.platforms.find((plat) => plat.download_url != '')
                )
                .slice(0, 6)"
              :key="`${download.name}_v${download.version}`"
            >
              <v-card class="black mx-4 mt-2">
                <v-card-text
                  class="px-0 pt-0 white--text"
                  :class="{ 'pb-2': download.notes }"
                >
                  <span class="text-h4" v-if="download_index == 0">
                    Latest download - v{{ download.version }}
                  </span>
                  <span class="text-h5" v-else> v{{ download.version }} </span>
                </v-card-text>
                <v-card-text class="px-0 pt-0" v-if="download.notes">
                  <em
                    ><strong class="white--text">NOTE:</strong>
                    {{ download.notes }}</em
                  >
                </v-card-text>
                <v-row>
                  <v-col
                    cols="4"
                    v-for="(platform, pIndex) in download.platforms"
                    :key="`${data.slug.toLowerCase()}_download-${
                      download.version
                    }-${platform.platform}`"
                  >
                    <v-row no-gutters>
                      <v-col><v-btn block tile color="info">Edit</v-btn></v-col>
                      <v-col
                        ><v-btn
                          @click.prevent="
                            deleteDownload(download_index, pIndex)
                          "
                          block
                          tile
                          color="error"
                          >Remove</v-btn
                        ></v-col
                      >
                    </v-row>
                    <v-btn
                      disabled
                      tile
                      block
                      x-large
                      :href="platform.download_url"
                      :outlined="
                        platform.platform == getPlatform && download_index == 0
                      "
                    >
                      <v-icon left>{{
                        getAppropriateIcon(platform.platform)
                      }}</v-icon>
                      Download
                    </v-btn>
                    <v-card-text class="pa-0 pt-2 text-subtitle-1"
                      >Checksum</v-card-text
                    >
                    <v-card-text class="text-subtitle-2 pa-0">
                      {{ platform.download_checksum.name }}:
                      {{ platform.download_checksum.checksum }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
    </v-row>
  </div>
</template>

<style>
/* @import "~@bitheral/easymde/dist/easymde.min.css"; */
@import "~@/assets/easymde.bith.css";
@import "~github-markdown-css";
@import "codemirror/theme/3024-night.css";
@import "highlightjs/styles/obsidian.css";

/* Hide scrollbar for Chrome, Safari and Opera */
#scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrollable {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

<script>
import hljs from "highlightjs";
import VueEasymde from "@bitheral/vue-easymde";

window.hljs = hljs;

export default {
  name: "ProjectTemplateDashboard",
  components: {
    VueEasymde,
  },
  props: {
    data: Object,
    action: String,
  },
  data() {
    return {
      images: {},
      noheader: false,
      styles: {},
      item: {},
    };
  },
  created() {
    this.resetChanges();
    this.processPageStyles(this.data.slug);
  },
  methods: {
    deepEqual(x, y) {
      return JSON.stringify(x) === JSON.stringify(y);
    },
    // resetChanges() {
    //   this.item = { ...this.data, downloads:  };
    // },
    deleteDownload(download_index, platform_index) {
      this.item.downloads[download_index].platforms.splice(platform_index, 1);
      console.log(this.data);
    },
    getImages(slug) {
      return slug
        ? {
            background: `https://cdn.bitheral.net/projects/${slug}/background.png`,
            header: `https://cdn.bitheral.net/projects/${slug}/header.png`,
            thumbnail: `https://cdn.bitheral.net/projects/${slug}/thumbnail.png`,
          }
        : `https://cdn.bitheral.net/projects/404.png`;
    },
    processPageStyles(slug) {
      this.images = this.getImages(slug);
      this.styles.background = `url(${this.images.background}) black`;

      if (Object.keys(this.data.meta).includes("page")) {
        let oldstyles = this.styles;

        // Check if the project has a styles key,
        // If it does create a variable for it
        // where it will look to see if it has a backgroundRepeat key with the value of no-repeat
        // If it does, set the backgroundSize to cover else set it to auto

        let dbstyles = this.data.meta.page.styles
          ? {
              ...this.data.meta.page.styles,
              backgroundSize:
                this.data.meta.page.styles.backgroundRepeat == "no-repeat"
                  ? "cover"
                  : "auto",
            }
          : {};

        // Combine the two styles
        this.styles = {
          ...oldstyles,
          position: "relative",
          ...dbstyles,
        };
      } else {
        this.styles = {
          background: `url(${this.images.background}) black`,
          backgroundRepeat: "repeat",
        };
      }
    },
    getAppropriateIcon(platform) {
      switch (platform.toLowerCase()) {
        case "windows":
          return `bi-windows`;
        case "mac":
          return `bi-apple`;
        case "linux":
          return `mdi-penguin`;
      }
    },
  },
  computed: {
    getPlatform() {
      // Get platform from UserAgent
      let platform = "";
      if (navigator.userAgent.indexOf("Mac") > -1) {
        platform = "mac";
      } else if (navigator.userAgent.indexOf("Linux") > -1) {
        platform = "linux";
      } else if (navigator.userAgent.indexOf("Windows") > -1) {
        platform = "windows";
      } else {
        platform = "unknown";
      }
      return platform;
    },
  },
};
</script>

<style></style>
