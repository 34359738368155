<template>
  <div class="fill-height">
    <v-main style="background-color: black" class="fill-height">
      <v-container
        fluid
        style="
          max-width: 100vw;
          padding: 0px;
          margin: 0px;
          background-color: black;
        "
        fill-height
      >
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
export default {
  name: "AdminApp",
  metaInfo() {
    return {
      titleTemplate: "Admin | %s",
    };
  },
};
</script>
