<template>
  <div>
    <v-row no-gutters align="center" justify="center">
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
      <v-col style="min-width: 45%">
        <v-img
          :key="`${data.slug}_header`"
          :src="this.header_url"
          :lazy-src="this.header_url"
          @error="handleHeaderError"
        >
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color="grey lighten-5"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
        <v-card
          color="black"
          style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
          elevation="12"
          :class="{ 'mb-12': !$vuetify.breakpoint.mobile }"
        >
          <v-list-item
            :two-line="!data.updatedAt"
            :three-line-line="data.updatedAt"
          >
            <v-list-item-content>
              <v-list-item-title class="text-h3">{{
                data.title
              }}</v-list-item-title>
              <v-list-item-subtitle class="text-h6 pb-1"
                >Posted on
                {{ formatDate(data.createdAt) }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="text-h6" v-if="data.updatedAt">
                <span>Last updated on </span>
                <span v-if="sameDate(data.createdAt, data.updatedAt)">{{
                  formatDateTime(data.updatedAt)
                }}</span>
                <span v-else>{{ formatDate(data.updatedAt) }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text
            class="text-body-1 white--text"
            v-html="data.body.html || parseMarkdown(data.body.md)"
          >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
    </v-row>
  </div>
</template>

<script>
// import PayPal from "vue-paypal-checkout";

export default {
  name: "PostView",
  props: {
    data: Object,
  },
  data() {
    return {
      header_url: "",
      post: {},
      images: {},
      blog_err: {},
    };
  },
  mounted() {
    this.header_url = `https://cdn.bitheral.net/blog/${this.data.slug}.png`;
  },
  methods: {
    handleHeaderError() {
      this.header_url = "https://cdn.bitheral.net/static_www/img/404.png";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    formatTime(date) {
      let timeStr = new Date(date).toLocaleTimeString();

      timeStr = timeStr.split(":")[0] + ":" + timeStr.split(":")[1];
      return timeStr;
    },
    formatDateTime(date) {
      return this.formatDate(date) + ", at " + this.formatTime(date);
    },
    sameDate(date1, date2) {
      return new Date(date1) === new Date(date2);
    },
  },
  // async created() {
  //   const post_slug = this.$route.params.slug;

  //   this.axios
  //     .get(`${process.env.VUE_APP_API_HOST}/v3/blog/post?slug=${post_slug}`)
  //     .then((response) => {
  //       if (response.status && response.status == 200) return response.data;
  //       else throw new Error("¯\\_(ツ)_/¯");
  //     })
  //     .then((data) => {
  //       this.post = data;
  //       this.images = this.getImage(this.post.slug);
  //       this.loading = false;
  //     })
  //     .catch(() => {
  //       this.blog_err = {
  //         title: "Whoops! That post doesn't exist.",
  //         message: "",
  //       };
  //       this.post = null;
  //       this.loading = false;
  //     });
  // },
};
</script>

<style></style>
