<template>
  <v-app style="height: 100vh; background: transparent">
    <admin-layout
      v-if="this.$route.meta.layout && this.$route.meta.layout == 'admin'"
    ></admin-layout>
    <default-layout v-else></default-layout>
  </v-app>
</template>

<script>
import defaultLayout from "./layouts/default.vue";
import adminLayout from "./layouts/admin.vue";

export default {
  name: "App",
  components: {
    adminLayout,
    defaultLayout,
  },
};
</script>
