import { initializeApp } from "firebase/app";

export const app = initializeApp({
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: "auth.bitheral.net",
  databaseURL: "https://bitheral-site.firebaseio.com",
  projectId: "bitheral-site",
  storageBucket: "bitheral-site.appspot.com",
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
});

export default app;
