<!-- Basically a redirect page in the event I'm not logged in -->

<template>
  <v-row no-gutters class="fill-height">
    <v-col></v-col>
    <v-col align-self="center" class="text-center"></v-col>
    <v-col></v-col>
  </v-row>
</template>

<script>
import {
  getAuth,
  signInWithPopup,
  GithubAuthProvider,
  signOut,
} from "firebase/auth";
import { app } from "../../plugins/firebase";
import { methods } from "../../router/guards";

const provider = new GithubAuthProvider();
const auth = getAuth(app);

export default {
  name: "AuthPage",
  methods: {
    checkAuth(result) {
      switch (result.code) {
        case 200:
          this.$router.push("/admin/dashboard");
          break;
        case 401:
          signOut(auth);
          this.$router.push("/");
          break;
      }
    },
  },
  async mounted() {
    let authResult = await methods.authenticate();
    this.checkAuth(authResult);

    switch (authResult.code) {
      case 500:
        signInWithPopup(auth, provider)
          .then(({ user }) => {
            if (user) return methods.authenticate();
            else return new Error("user-not-found");
          })
          .then((result) => {
            this.checkAuth(result);
          })
          .catch((err) => {
            const errorStates = {
              "popup-closed-by-user": 0,
              "user-not-found": 0,
            };

            let error = err.toString();
            let key = Object.keys(errorStates).find((key) =>
              error.includes(key)
            );

            switch (errorStates[key]) {
              case 0:
                this.$router.push("/");
                break;
            }
          });
        break;
    }
  },
};
</script>
