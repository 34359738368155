import Vue from "vue";
import VueRouter from "vue-router";

import HomeView from "../views/showcase/Projects.vue";
import BlogView from "../views/blog/Blog.vue";
import ProjectView from "../views/showcase/Project.vue";
import PostView from "../views/blog/Post.vue";

import AdminAuthView from "../views/admin/auth.vue";

import AdminDashboardView from "../views/admin/dashboard/index.vue";

import guards from "./guards";
// import { getAuth, onAuthStateChanged } from "firebase/auth";
// import { app } from "../plugins/firebase";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/project/:slug",
    name: "project",
    component: ProjectView,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/blog/:slug",
    name: "blog-post",
    component: PostView,
    meta: {
      layout: "default",
    },
  },
  {
    path: "/admin/auth",
    name: "admin-auth",
    component: AdminAuthView,
    meta: {
      layout: "admin",
    },
  },
  {
    path: "/admin/dashboard",
    name: "admin-dashboard",
    component: AdminDashboardView,
    meta: {
      layout: "admin",
    },
    beforeEnter: (to, from, next) => guards.auth(to, from, next),
  },
  {
    path: "/admin",
    redirect: { path: "/admin/auth" },
  },
  {
    path: "*",
    component: HomeView,
    meta: {
      layout: "default",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
