<template>
  <div style="height: 100vh; width: 100vw">
    <v-system-bar height="36">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/" v-bind="attrs" v-on="on">
            <v-icon>bi-house-door</v-icon>
          </v-btn>
        </template>
        <span>Go to homepage</span>
      </v-tooltip>
      <v-card-text>Hey, {{ auth.currentUser.displayName }}!</v-card-text>
      <v-spacer />
      <v-tooltip left color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click.prevent="signOut" v-bind="attrs" v-on="on">
            <v-icon right>bi-box-arrow-in-left</v-icon>
          </v-btn>
        </template>
        <span>Sign out</span>
      </v-tooltip>
    </v-system-bar>
    <v-container fluid>
      <v-card height="100%" color="transparent">
        <v-card color="transparent">
          <v-card-title>
            Projects
            <v-spacer />
            <v-btn
              outlined
              large
              @click.prevent="
                dialogs.new = true;
                selectedData = { ...template.project, type: 'project' };
              "
              >Add new project</v-btn
            >
          </v-card-title>
          <v-slide-group
            v-model="selectedData"
            active-class="success"
            show-arrows
          >
            <v-slide-item
              v-for="(project, index) in projects"
              :key="index"
              :value="{ ...project, type: 'project' }"
              v-slot="{ active, toggle }"
            >
              <v-card class="ma-2" height="200" width="300" @click="toggle">
                <v-img
                  :src="
                    project.screenshots.length > 0
                      ? `https://cdn.bitheral.net/projects/${project.slug}/thumbnail.png`
                      : `https://cdn.bitheral.net/static_www/img/404.png`
                  "
                  height="200"
                  width="300"
                >
                  <div
                    :style="{
                      width: '100%',
                      height: '100%',
                      background:
                        active || !project.meta.show ? 'rgba(0,0,0,0.5)' : '',
                    }"
                  >
                    <v-card-actions
                      v-show="!project.meta.show && !active"
                      class="warning--text"
                    >
                      <v-icon class="warning--text" left
                        >bi-exclamation-triangle-fill</v-icon
                      >
                      Hidden
                    </v-card-actions>
                    <v-card-actions v-show="active" style="display: block">
                      <div>
                        <v-row dense>
                          <v-col cols="auto">
                            <v-btn
                              disabled
                              color="primary"
                              @click="toggle"
                              @click.prevent="dialogs.edit = true"
                            >
                              <v-icon>bi-pen</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="auto">
                            <v-btn
                              color="error"
                              @click="toggle"
                              @click.prevent="dialogs.delete = true"
                            >
                              <v-icon>bi-trash</v-icon>
                            </v-btn>
                          </v-col>
                        </v-row>
                      </div>
                      <div>
                        <v-row dense>
                          <v-card-title class="white--text">
                            {{
                              project.screenshots.length === 0
                                ? project.name
                                : ""
                            }}
                          </v-card-title>
                        </v-row>
                      </div>
                    </v-card-actions>
                  </div>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
        <v-card color="transparent">
          <v-card-title>
            Blog <v-spacer />
            <v-btn
              outlined
              large
              @click.prevent="
                dialogs.new = true;
                selectedData = { ...template.blog, type: 'blog' };
              "
              >Create post</v-btn
            >
          </v-card-title>
          <v-slide-group v-model="selectedData" show-arrows>
            <v-slide-item
              v-for="(post, index) in blog.posts"
              :key="index"
              :value="{ ...post, type: 'blog' }"
              v-slot="{ active, toggle }"
            >
              <v-card class="ma-2" height="200" width="300" @click="toggle">
                <v-img
                  :src="`https://cdn.bitheral.net/blog/${post.slug}.png`"
                  height="200"
                  width="300"
                >
                  <div
                    :style="{
                      width: '100%',
                      height: '100%',
                      background:
                        active || post.meta.hidden ? 'rgba(0,0,0,0.5)' : '',
                    }"
                  >
                    <v-card-actions
                      v-show="post.meta.hidden && !active"
                      class="warning--text"
                    >
                      <v-icon class="warning--text" left
                        >bi-exclamation-triangle-fill</v-icon
                      >
                      Hidden
                    </v-card-actions>
                    <v-card-actions v-show="active">
                      <v-btn
                        disabled
                        color="primary"
                        @click="toggle"
                        @click.prevent="dialogs.edit = true"
                      >
                        <v-icon>bi-pen</v-icon>
                      </v-btn>
                      <v-btn
                        color="error"
                        @click="toggle"
                        @click.prevent="dialogs.delete = true"
                      >
                        <v-icon>bi-trash</v-icon>
                      </v-btn>
                    </v-card-actions>
                  </div>
                </v-img>
              </v-card>
            </v-slide-item>
          </v-slide-group>
        </v-card>
      </v-card>
    </v-container>
    <v-dialog
      ref="editDialog"
      v-model="dialogs.edit"
      width="75%"
      @click:outside="handleDialog($event, dialogs.last)"
      id="scrollable"
    >
      <Project
        v-if="selectedData?.type === 'project'"
        :key="`${selectedData?.slug}-project`"
        :data="selectedData"
      ></Project>
      <blog-post
        v-else-if="selectedData?.type === 'blog'"
        :key="`${selectedData?.slug}-blog`"
        :data="selectedData"
      ></blog-post>
    </v-dialog>
    <v-dialog
      ref="newDialog"
      v-model="dialogs.new"
      :width="`${selectedData?.type == 'project' ? '50%' : '75%'}`"
    >
      <new-item
        :key="`new_${selectedData?.type || 'item'}`"
        :data="selectedData"
      ></new-item>
    </v-dialog>
    <v-dialog
      ref="deleteDialog"
      v-model="dialogs.delete"
      width="50%"
      @click:outside="handleDialog($event, dialogs.last)"
    >
      <v-card style="user-select: none">
        <v-card-title
          >Delete &quot;{{
            selectedData?.name || selectedData?.title
          }}&quot;</v-card-title
        >
        <v-card-text>
          Are you sure you want to delete "{{
            selectedData?.name || selectedData?.title
          }}"? This action cannot be undone.
        </v-card-text>
        <v-text-field
          class="px-4"
          v-model="confirmDelete"
          label='Type "DELETE" to confirm'
          :rules="[(v) => v === 'DELETE' || 'Please type &quot;Delete&quot;']"
          filled
          solo
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="handleDialog(null, 'delete')"
            @click.prevent="deleteItem()"
            :disabled="confirmDelete !== 'DELETE'"
          >
            Delete
          </v-btn>
          <v-btn
            color="primary"
            text
            @click.prevent="handleDialog(null, 'delete', false)"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { getAuth, signOut } from "firebase/auth";
import { app } from "../../../plugins/firebase";

import Project from "@/components/templates/Project.vue";
import BlogPost from "@/components/templates/BlogPost.vue";
import NewItem from "@/components/templates/NewItem.vue";

import axiosInstance from "@/plugins/axios";

const auth = getAuth(app);

export default {
  name: "admin-dashboard",
  data: () => ({
    confirmDelete: "",
    selectedData: null,
    dialogs: {
      edit: false,
      new: false,
      delete: false,
    },
    projects: [],
    blog: [],
    template: {
      project: {
        slug: "",
        name: "",
        body: {
          md: "",
          html: "",
        },
        order: 0,
        tags: [],
        downloads: [],
        collaborators: [],
        screenshots: [],
        meta: {
          svc_url: "",
          show: true,
          content_type: "",
          page: {
            styles: {},
            description: "",
          },
        },
      },
      blog: {
        slug: "",
        title: "",
        blurb: {
          md: "",
          html: "",
        },
        body: {
          md: "",
          html: "",
        },
        createdAt: "",
        updatedAt: null,
        meta: {
          hidden: false,
          views: 0,
          url: "",
        },
      },
    },
    auth,
  }),
  components: {
    Project,
    BlogPost,
    NewItem,
  },
  mounted() {
    const urls = {
      "/v3/projects": "projects",
      "/v3/blog/posts/all?perPage=50": "blog",
    };

    for (const [url, key] of Object.entries(urls)) {
      this.axios
        .get(process.env.VUE_APP_API_HOST + url)
        .then((response) => {
          const { data } = response;
          if (key === "projects") {
            this.template.project.order = data.length + 1;
            data.sort((a, b) => a.order - b.order);
            data.reverse();
          }

          this[key] = data;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  },
  metaInfo() {
    return {
      title: "Dashboard",
    };
  },
  methods: {
    signOut() {
      signOut(this.auth);
      this.$router.push("/");
    },
    async deleteItem() {
      const apiEndpoints = {
        project: `/v3/projects/delete?slugs=${this.selectedData.slug}`,
        blog: `/v3/blog/delete?slugs=${this.selectedData.slug}`,
      };

      const nAxiosInstance = await axiosInstance();
      nAxiosInstance.delete(apiEndpoints[this.selectedData.type]);
      this.dialogs.delete = false;

      // Trigger a page reload
      setTimeout(() => {
        this.$router.go();
      }, 1000);
    },

    handleDialog(event, type, other = null) {
      if (event instanceof MouseEvent) {
        // Check if the click event target is outside the dialog
        const contains = this.$refs.editDialog.$el.contains(event.target);
        this.dialogs[type] = contains;
        switch (type) {
          case "item":
            this.selectedData = null;
            break;
        }
      } else {
        if (type === "delete") {
          this.confirmDelete = "";
        }

        if (other !== null) {
          this.dialogs[type] = other;
        } else {
          this.dialogs[type] = true;
        }
      }
    },
  },
};
</script>
