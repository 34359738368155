<template>
  <div>
    <div v-if="!loading">
      <div v-if="posts.length > 0">
        <v-container fluid class="pa-12">
          <v-card-text class="text-center fill-height pa-0">
            <v-row>
              <v-col md="4" v-for="post in posts" :key="post.slug">
                <v-card class="mx-auto text-left" color="black">
                  <v-img
                    :ref="'blog_header_' + post.slug"
                    :src="getImage(post.slug)"
                    @error="imageNotFound(post.slug)"
                    height="194"
                    contain
                  ></v-img>
                  <v-list-item
                    :two-line="!post.updatedAt"
                    :three-line-line="post.updatedAt"
                  >
                    <v-list-item-content>
                      <v-list-item-title class="text-h4">{{
                        post.title
                      }}</v-list-item-title>
                      <v-list-item-subtitle class="pb-1">{{
                        formatDate(post.createdAt)
                      }}</v-list-item-subtitle>
                      <v-list-item-subtitle v-if="post.updatedAt">
                        <span>Last updated on </span>
                        <span v-if="sameDate(post.createdAt, post.updatedAt)">{{
                          formatDateTime(post.updatedAt)
                        }}</span>
                        <span v-else>{{ formatDate(post.updatedAt) }}</span>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-card-text
                    class="py-0 my-0"
                    v-html="
                      post.blurb.html ||
                      parseMarkdown(
                        post.blurb.md ||
                          (post.body.md.length > 235
                            ? `${post.body.md.substring(0, 235)}...`
                            : `${post.body.md}`)
                      )
                    "
                  >
                  </v-card-text>
                  <v-card-actions>
                    <v-btn outlined class="px-4 ma-2" :to="`/blog/${post.slug}`"
                      >Read</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-container>
        <v-pagination
          v-if="pagination.pages > 1"
          v-model="pagination.page"
          :length="pagination.pages"
          @input="next"
        ></v-pagination>
      </div>
      <div v-else>
        <v-container fluid>
          <v-row justify="center" align="center" style="min-height: 60vh">
            <v-col md="4" justify="center" align="center">
              <v-card class="elevation-0 transparent">
                <v-card-text
                  class="text-center white--text"
                  style="user-select: none"
                >
                  <h1
                    :class="{
                      'text-h1': !$vuetify.breakpoint.mobile,
                      'text-h2': $vuetify.breakpoint.mobile,
                    }"
                    class="pb-12"
                  >
                    ¯\_(ツ)_/¯
                  </h1>
                  <h5
                    :class="{
                      'text-h5': !$vuetify.breakpoint.mobile,
                      'text-body-1': $vuetify.breakpoint.mobile,
                    }"
                  >
                    There's no posts right now...
                  </h5>
                  <h6
                    :class="{
                      'text-h6': !$vuetify.breakpoint.mobile,
                      'text-body-2': $vuetify.breakpoint.mobile,
                    }"
                  >
                    Come back here soon!
                  </h6>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <v-container fluid class="pa-12">
        <v-card-text class="text-center fill-height pa-0">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                indeterminate
                color="white"
                size="64"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      loading: true,
      page: 1,
      pagination: {
        page: 1,
        pages: 1,
        per_page: 9,
        total: 1,
      },
      socials: {
        facebook: {
          background: "#3b5998",
        },
        twitter: {
          background: "#00aced",
        },
        reddit: {
          background: "#ff4500",
        },
        linkedin: {
          background: "#007bb5",
        },
      },
      posts: [],
      postHeight: 256 + 128,
      postWidth: 512,
    };
  },
  methods: {
    getImage(slug) {
      return `https://cdn.bitheral.net/blog/${slug}.png`;
    },
    imageNotFound(slug) {
      //console.log(this.$refs['blog_header_' + slug)
      this.$refs["blog_header_" + slug][0].src =
        "https://cdn.bitheral.net/static_www/img/404.png";
    },
    next(page) {
      this.axios
        .get(
          `${process.env.VUE_APP_API_HOST}/v3/blog/posts?&page=${page}&perPage=${this.pagination.per_page}`
        )
        .then((response) => {
          if (response.status == 200) return response.data;
        })
        .then((data) => {
          this.posts = data.posts;
          this.pagination = {
            page: page,
            pages: data.meta.totalPages,
            per_page: data.meta.postsPerPage,
            total: data.meta.totalPosts,
          };
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.posts = [];
        });
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    formatTime(date) {
      let timeStr = new Date(date).toLocaleTimeString();

      // Remove seconds from time string
      timeStr = timeStr.split(":")[0] + ":" + timeStr.split(":")[1];
      return timeStr;
    },
    formatDateTime(date) {
      return this.formatDate(date) + ", at " + this.formatTime(date);
    },
    sameDate(date1, date2) {
      return new Date(date1).toDateString() === new Date(date2).toDateString();
    },
  },
  mounted() {
    this.next(this.$route.query.page || 1);
  },
  metaInfo() {
    return {
      title: "Blog",
    };
  },
};
</script>
