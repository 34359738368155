<template>
  <div>
    <div v-if="!loading">
      <v-tabs
        centered
        color="white"
        background-color="black"
        v-if="!isMobileDevice()"
      >
        <v-tabs-slider
          :color="
            $root.projects.some(
              (project) => project.slug === $route.params.slug
            )
              ? 'white'
              : 'transparent'
          "
        ></v-tabs-slider>
        <v-tab
          v-for="page in $root.projects"
          :key="`${page.slug.toLowerCase()}_tab`"
          :to="`/project/${page.slug}`"
        >
          <span>{{ page.name }}</span>
        </v-tab>
      </v-tabs>
      <div v-if="project != null">
        <v-row no-gutters :style="styles" align="center" justify="center">
          <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
          <v-col style="min-width: 50%">
            <v-img
              v-if="!noheader"
              :src="this.images.header"
              contain
              class="my-12"
              @error="noheader = true"
              eager
            ></v-img>
            <v-card-text
              style="user-select: none"
              :style="{
                'font-size: 5rem !important': !isMobileDevice(),
              }"
              class="text-center text-uppercase py-12"
              :class="{
                'text-h1': !$vuetify.breakpoint.mobile,
                'text-h3': $vuetify.breakpoint.mobile,
              }"
              v-else
            >
              {{ project.name }}
            </v-card-text>
            <v-card
              color="black"
              style="
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
              "
              elevation="12"
            >
              <v-card
                v-if="
                  project.meta.availableInWeb &&
                  !isMobileDevice() &&
                  !$vuetify.breakpoint.mobile
                "
                color="transparent"
              >
                <v-card-text v-if="!isPlaying" class="fill-height">
                  <v-img
                    :src="this.images.thumbnail"
                    height="520px"
                    gradient="to top, rgba(0, 0, 0,0.5), rgba(0,0,0,.5)"
                  >
                    <v-row align="center" justify="center" class="fill-height">
                      <v-col cols="auto">
                        <v-btn dark x-large outlined @click="isPlaying = true">
                          <span>Play {{ project.name }}</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-img>
                </v-card-text>
                <v-card-text v-else>
                  <unity
                    :src="`https://cdn.bitheral.net/projects/${project.slug}/web/Web.json`"
                    width="100%"
                    height="100%"
                    :unityLoader="`https://cdn.bitheral.net/projects/${project.slug}/web/UnityLoader.js`"
                  ></unity>
                  <v-btn outlined @click="isPlaying = !isPlaying"
                    >Stop {{ project.name }}</v-btn
                  >
                </v-card-text>
              </v-card>
              <v-row class="py-4" align="center" justify="center">
                <v-col cols="auto">
                  <v-btn
                    outlined
                    height="48px"
                    width="auto"
                    class="px-8"
                    :href="project.meta.svc_url"
                    target="_blank"
                    rel="nofollow author external"
                  >
                    <v-icon class="bi-github pr-2" left size="26px"></v-icon>
                    View project on GitHub
                  </v-btn>
                </v-col>
              </v-row>

              <!-- About -->
              <v-card-text class="text-h3 white--text pb-0"
                >About {{ project.name }}</v-card-text
              >
              <v-card-text
                class="text-body-1 white--text"
                v-html="project.body.html || parseMarkdown(project.body.md)"
              ></v-card-text>

              <div v-if="project.screenshots.length != 0">
                <!-- Screenshots -->
                <v-card-text class="text-h3 white--text"
                  >Screenshots</v-card-text
                >
                <v-carousel
                  hide-delimiters
                  class="px-4"
                  continuous
                  cycle
                  :show-arrows="project.screenshots.length > 1"
                  :height="isMobileDevice() ? '256px' : 'auto'"
                >
                  <v-carousel-item
                    v-for="(screenshot, index) in project.screenshots"
                    :key="`${project.slug}_screenshot-${index + 1}`"
                    :src="
                      screenshot.src ||
                      `https://cdn.bitheral.net/projects/${project.slug}/screenshots/${project.slug}-${index}.png`
                    "
                    eager
                  >
                  </v-carousel-item>
                </v-carousel>
              </div>

              <!-- Credits -->
              <div v-if="project.collaborators.length != 0">
                <v-card-text class="text-h3 white--text pb-0"
                  >Collaborators</v-card-text
                >
                <v-list-item
                  class="mx-1"
                  two-line
                  v-for="contributor in project.collaborators"
                  :key="`${
                    project.slug
                  }_contributor-${contributor.name.toLowerCase()}`"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-h5">{{
                      contributor.name
                    }}</v-list-item-title>
                    <v-list-item-subtitle class="text-h6">{{
                      contributor.roles.join(", ")
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </div>

              <!-- Downloads -->
              <div v-if="project.downloads.length != 0 && !isMobileDevice()">
                <v-card-text class="text-h3 white--text pb-0"
                  >Downloads</v-card-text
                >
                <div
                  v-for="(download, download_index) in project.downloads
                    .filter((dl) =>
                      dl.platforms.find((plat) => plat.download_url != '')
                    )
                    .slice(0, 6)"
                  :key="`${download.name}_v${download.version}`"
                >
                  <v-card class="black mx-4 mt-2">
                    <v-card-text
                      class="px-0 pt-0 white--text"
                      :class="{ 'pb-2': download.notes }"
                    >
                      <span class="text-h4" v-if="download_index == 0">
                        Latest download - v{{ download.version }}
                      </span>
                      <span class="text-h5" v-else>
                        Download {{ download.name }} v{{ download.version }}
                      </span>
                    </v-card-text>
                    <v-card-text class="px-0 pt-0" v-if="download.notes">
                      <em
                        ><strong class="white--text">NOTE:</strong>
                        {{ download.notes }}</em
                      >
                    </v-card-text>
                    <v-row>
                      <v-col
                        cols="4"
                        v-for="platform in download.platforms"
                        :key="`${project.slug.toLowerCase()}_download-${
                          download.version
                        }-${platform.platform}`"
                      >
                        <v-btn
                          block
                          x-large
                          :href="platform.download_url"
                          :outlined="
                            platform.platform == getPlatform &&
                            download_index == 0
                          "
                        >
                          <v-icon left>{{
                            getAppropriateIcon(platform.platform)
                          }}</v-icon>
                          Download
                        </v-btn>
                        <v-card-text class="pa-0 pt-2 text-subtitle-1"
                          >Checksum</v-card-text
                        >
                        <v-card-text class="text-subtitle-2 pa-0">
                          {{ platform.download_checksum.name }}:
                          {{ platform.download_checksum.checksum }}
                        </v-card-text>
                      </v-col>
                    </v-row>
                  </v-card>
                </div>
              </div>
            </v-card>
          </v-col>
          <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
        </v-row>
      </div>
      <div v-else>
        <v-container fluid>
          <!-- Justify and align center -->
          <v-row justify="center" align="center" style="min-height: 60vh">
            <v-col cols="4" justify="center" align="center">
              <v-card class="elevation-0 transparent">
                <v-card-text
                  class="text-center white--text"
                  style="user-select: none"
                >
                  <h1 class="text-h1 pb-4">¯\_(ツ)_/¯</h1>
                  <h5 class="text-h5">{{ project_err.title }}</h5>
                  <h6 class="text-h6">{{ project_err.message }}</h6>
                  <v-spacer></v-spacer>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <div v-else>
      <v-container fluid class="pa-12">
        <v-card-text class="text-center fill-height pa-0">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-progress-circular
                indeterminate
                color="white"
                size="64"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
      </v-container>
    </div>
  </div>
</template>

<script>
// import PayPal from "vue-paypal-checkout";
import Unity from "vue-unity-webgl";

export default {
  name: "ProjectView",
  data() {
    return {
      currentTab: 0,
      isPlaying: false,
      project: null,
      images: {},
      noheader: false,
      styles: {},
      loading: true,
      project_err: {
        message: "",
      },
    };
  },
  components: {
    Unity,
  },
  metaInfo() {
    return {
      title: this.project ? this.project.name : "Project not found",
      meta: [
        {
          name: "description",
          content: this.project
            ? this.project.meta.page.description
            : "Project not found",
        },
        {
          name: "og:title",
          content: this.project ? this.project.name : "Project not found",
        },
        {
          name: "og:description",
          content: this.project
            ? this.project.meta.page.description
            : "Project not found",
        },
        {
          name: "og:image",
          content: this.project
            ? `https://cdn.bitheral.net/projects/${this.project.slug}/header.png`
            : "https://cdn.bitheral.net/projects/404.png",
        },
        {
          name: "og:url",
          content: this.project
            ? `https://bitheral.net/projects/${this.project.slug}`
            : "https://bitheral.net/projects/404",
        },
        {
          name: "twitter:title",
          content: this.project ? this.project.name : "Project not found",
        },
        {
          name: "twitter:description",
          content: this.project
            ? this.project.meta.page.description
            : "Project not found",
        },
        {
          name: "twitter:image",
          content: this.project
            ? `https://cdn.bitheral.net/projects/${this.project.slug}/header.png`
            : "https://cdn.bitheral.net/projects/404.png",
        },
        {
          name: "twitter:card",
          content: "summary_large_image",
        },
      ],
    };
  },
  methods: {
    getImages(slug) {
      return slug
        ? {
            background: `https://cdn.bitheral.net/projects/${slug}/background.png`,
            header: `https://cdn.bitheral.net/projects/${slug}/header.png`,
            thumbnail: `https://cdn.bitheral.net/projects/${slug}/thumbnail.png`,
          }
        : `https://cdn.bitheral.net/projects/404.png`;
    },
    processPageStyles(slug) {
      this.images = this.getImages(slug);
      this.styles.background = `url(${this.images.background}) black`;

      if (Object.keys(this.project.meta).includes("page")) {
        let oldstyles = this.styles;

        // Check if the project has a styles key,
        // If it does create a variable for it
        // where it will look to see if it has a backgroundRepeat key with the value of no-repeat
        // If it does, set the backgroundSize to cover else set it to auto

        let dbstyles = this.project.meta.page.styles
          ? {
              ...this.project.meta.page.styles,
              backgroundSize:
                this.project.meta.page.styles.backgroundRepeat == "no-repeat"
                  ? "cover"
                  : "auto",
            }
          : {};

        // Combine the two styles
        this.styles = {
          ...oldstyles,
          position: "relative",
          ...dbstyles,
        };
      } else {
        this.styles = {
          background: `url(${this.images.background}) black`,
          backgroundRepeat: "repeat",
        };
      }
    },
    getAppropriateIcon(platform) {
      switch (platform.toLowerCase()) {
        case "windows":
          return `bi-windows`;
        case "mac":
          return `bi-apple`;
        case "linux":
          return `mdi-penguin`;
      }
    },
    // getProjects() {
    //   this.axios
    //     .get(process.env.VUE_APP_API_HOST + "/v3/projects")
    //     .then((response) => {
    //       if (response.status == 200) return response.data;
    //       else throw new Error("Error fetching projects");
    //     })
    //     .then((data) => {
    //       let localStorageData = data;
    //       localStorageData = localStorageData.filter(
    //         (project) => project.meta.show
    //       );

    //       localStorageData.sort((a, b) => {
    //         return a.order - b.order;
    //       });

    //       this.setLocal("projects", localStorageData);
    //       this.projects = localStorageData;
    //     })
    //     .catch(() => {
    //       this.projects = [];
    //     });
    // },
  },
  computed: {
    getPlatform() {
      // Get platform from UserAgent
      let platform = "";
      if (navigator.userAgent.indexOf("Mac") > -1) {
        platform = "mac";
      } else if (navigator.userAgent.indexOf("Linux") > -1) {
        platform = "linux";
      } else if (navigator.userAgent.indexOf("Windows") > -1) {
        platform = "windows";
      } else {
        platform = "unknown";
      }
      return platform;
    },
  },
  mounted() {
    const slug = this.$route.params.slug;
    this.project = (this.$root.projects || this.getLocal("projects")).find(
      (project) => project.slug == slug
    );

    if (this.project) {
      this.processPageStyles(slug);
      this.loading = false;
    } else {
      let database_url = `${process.env.VUE_APP_API_HOST}/v3/projects/${slug}`;
      this.axios
        .get(database_url)
        .then((response) => {
          if (response.status == 200) return response.data;
        })
        .then((data) => {
          this.project = data;
          this.processPageStyles(slug);
          this.loading = false;
        })
        .catch((err) => {
          if (err.response && err.response.status == 404) {
            this.project_err = {
              title: "Whoops! That project doesn't exist.",
              message: "",
            };
            this.project = null;
            this.loading = false;
            return;
          }

          if (err.code == "ERR_NETWORK" || err.code == "ECONNABORTED") {
            this.project_err = {
              title: "Whoops! We couldn't find that project.",
              message: "Please try again later.",
            };
            this.project = null;
            this.loading = false;
          }
        });
    }
  },
};
</script>

<style></style>
