var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$root.profile)?_c('v-card',{staticClass:"elevation-0 black pa-0",staticStyle:{"user-select":"none"},attrs:{"tile":""}},[_c('v-img',{attrs:{"src":`${_vm.cdn_url}/renders/Luna/large`,"srcset":`
        ${_vm.cdn_url}/renders/Luna/xsmall 240w,
				${_vm.cdn_url}/renders/Luna/small 480w,
				${_vm.cdn_url}/renders/Luna/medium 960w,
      `,"sizes":"(max-width: 600px) 240px,\n             (max-width: 960px) 480px,\n             3840px","alt":"Luna","position":"center center","eager":"","lazy-src":`${_vm.cdn_url}/renders/Luna/xsmall`,"max-height":"333"}},[_c('v-container',{attrs:{"fill-height":"","id":"prfl-container"}},[_c('v-layout',{staticClass:"white--text",attrs:{"align-center":"","justify-center":"","column":""}},[_c('v-flex',{attrs:{"xs12":""}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-avatar',{attrs:{"size":"128","rounded":"true"}},[_c('v-img',{attrs:{"contain":"","src":`${_vm.cdn_url}/static_www/img/logo/128`}})],1),_c('h1',{staticClass:"text-h4 pb-0 pt-3"},[_vm._v(_vm._s(_vm.$root.profile.name))]),_c('h2',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"text-h6 pb-3"},[_vm._v(" "+_vm._s(_vm.$root.profile.bio)+" ")]),_c('v-row',{class:{
                  'pt-3': _vm.$vuetify.breakpoint.smAndDown,
                },attrs:{"dense":"","justify":"center"}},_vm._l((_vm.$root.profile.socials),function(social){return _c('v-col',{key:`social_${social.platform.toLowerCase()}`,attrs:{"cols":"auto"}},[_c('v-chip',{directives:[{name:"show",rawName:"v-show",value:(social.link || _vm.$vuetify.breakpoint.smAndUp),expression:"social.link || $vuetify.breakpoint.smAndUp"}],staticClass:"noHighlight",attrs:{"outlined":"","color":"white","href":social.link,"target":"_blank","rel":"nofollow author external","ripple":false}},[_c('v-icon',{staticClass:"pl-0 pr-2",class:social.icon,attrs:{"size":"16px"}}),_vm._v(_vm._s(social.handle)+" ")],1)],1)}),1)],1)],1)],1)],1),_c('div',{attrs:{"id":"particles-js"}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }