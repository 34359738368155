import axios from "axios";

import { getAuth } from "firebase/auth";

const instance = async () => {
  const auth = getAuth();
  const user = await auth.currentUser;
  const token = await user.getIdToken();

  const { apiKey } = (
    await axios.get(process.env.VUE_APP_API_HOST + "/v3/auth/apiKey", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
  ).data;

  return axios.create({
    baseURL: process.env.VUE_APP_API_HOST,
    headers: {
      Authorization: `Bearer ${token}`,
      "X-API-KEY": apiKey,
    },
  });
};

export default instance;
