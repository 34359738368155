var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loading)?_c('div',[(_vm.posts.length > 0)?_c('div',[_c('v-container',{staticClass:"pa-12",attrs:{"fluid":""}},[_c('v-card-text',{staticClass:"text-center fill-height pa-0"},[_c('v-row',_vm._l((_vm.posts),function(post){return _c('v-col',{key:post.slug,attrs:{"md":"4"}},[_c('v-card',{staticClass:"mx-auto text-left",attrs:{"color":"black"}},[_c('v-img',{ref:'blog_header_' + post.slug,refInFor:true,attrs:{"src":_vm.getImage(post.slug),"height":"194","contain":""},on:{"error":function($event){return _vm.imageNotFound(post.slug)}}}),_c('v-list-item',{attrs:{"two-line":!post.updatedAt,"three-line-line":post.updatedAt}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h4"},[_vm._v(_vm._s(post.title))]),_c('v-list-item-subtitle',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.formatDate(post.createdAt)))]),(post.updatedAt)?_c('v-list-item-subtitle',[_c('span',[_vm._v("Last updated on ")]),(_vm.sameDate(post.createdAt, post.updatedAt))?_c('span',[_vm._v(_vm._s(_vm.formatDateTime(post.updatedAt)))]):_c('span',[_vm._v(_vm._s(_vm.formatDate(post.updatedAt)))])]):_vm._e()],1)],1),_c('v-card-text',{staticClass:"py-0 my-0",domProps:{"innerHTML":_vm._s(
                    post.blurb.html ||
                    _vm.parseMarkdown(
                      post.blurb.md ||
                        (post.body.md.length > 235
                          ? `${post.body.md.substring(0, 235)}...`
                          : `${post.body.md}`)
                    )
                  )}}),_c('v-card-actions',[_c('v-btn',{staticClass:"px-4 ma-2",attrs:{"outlined":"","to":`/blog/${post.slug}`}},[_vm._v("Read")]),_c('v-spacer')],1)],1)],1)}),1)],1)],1),(_vm.pagination.pages > 1)?_c('v-pagination',{attrs:{"length":_vm.pagination.pages},on:{"input":_vm.next},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}}):_vm._e()],1):_c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"min-height":"60vh"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"md":"4","justify":"center","align":"center"}},[_c('v-card',{staticClass:"elevation-0 transparent"},[_c('v-card-text',{staticClass:"text-center white--text",staticStyle:{"user-select":"none"}},[_c('h1',{staticClass:"pb-12",class:{
                    'text-h1': !_vm.$vuetify.breakpoint.mobile,
                    'text-h2': _vm.$vuetify.breakpoint.mobile,
                  }},[_vm._v(" ¯\\_(ツ)_/¯ ")]),_c('h5',{class:{
                    'text-h5': !_vm.$vuetify.breakpoint.mobile,
                    'text-body-1': _vm.$vuetify.breakpoint.mobile,
                  }},[_vm._v(" There's no posts right now... ")]),_c('h6',{class:{
                    'text-h6': !_vm.$vuetify.breakpoint.mobile,
                    'text-body-2': _vm.$vuetify.breakpoint.mobile,
                  }},[_vm._v(" Come back here soon! ")]),_c('v-spacer')],1)],1)],1)],1)],1)],1)]):_c('div',[_c('v-container',{staticClass:"pa-12",attrs:{"fluid":""}},[_c('v-card-text',{staticClass:"text-center fill-height pa-0"},[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"white","size":"64"}})],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }