import Vue from "vue";
import VueMeta from "vue-meta";
import VueAxios from "vue-axios";

import axios from "axios";
import { marked } from "marked";
import hljs from "highlightjs";

// import VueSocialSharing from "vue-social-sharing";
// import VueBraintree from "vue-braintree";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import firebase from "./plugins/firebase";

// Make a request to the server to get the particle config
fetch("https://cdn.bitheral.net/static_www/packages/particles/configs.json")
  .then((response) => {
    if (response.status !== 200) {
      throw new Error("Could not fetch particle configs. Falling back");
    } else {
      console.log("Got particle configs from CDN");
      return response.json();
    }
  })
  .then((particleConfigs) => {
    let loadedParticleConfig = null;

    // Get each particle configs
    for (const [, value] of Object.entries(particleConfigs)) {
      // Get type of event for the config
      const events = Object.keys(value.event);

      if (events.length === 0) continue;

      if (events.includes("date")) {
        // If the current date is between the start and end date, load the config
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        // Check of the current date is between the start and end date
        if (
          currentMonth >= value.event.date.start.month - 1 &&
          currentMonth <= value.event.date.end.month - 1 &&
          currentDay >= value.event.date.start.day &&
          currentDay <= value.event.date.end.day
        ) {
          loadedParticleConfig = value.url;
          break;
        }
        break;
      }
    }

    if (loadedParticleConfig) {
      window.particlesJS.load(
        "particles-js",
        loadedParticleConfig,
        function () {
          console.log("Particles loaded");
        }
      );
    }
  })
  .catch((err) => {
    console.error(err);
    const particleConfigs = {
      snow: {
        url: "https://cdn.bitheral.net/static_www/packages/particles/snow.json",
        event: {
          date: {
            start: {
              month: 12,
              day: 19,
            },
            end: {
              month: 12,
              day: 31,
            },
          },
        },
      },
    };

    let loadedParticleConfig = null;

    // Get each particle configs
    for (const [, value] of Object.entries(particleConfigs)) {
      // Get type of event for the config
      const events = Object.keys(value.event);

      if (events.length === 0) continue;

      if (events.includes("date")) {
        // If the current date is between the start and end date, load the config
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        // Check of the current date is between the start and end date
        if (
          currentMonth >= value.event.date.start.month - 1 &&
          currentMonth <= value.event.date.end.month - 1 &&
          currentDay >= value.event.date.start.day &&
          currentDay <= value.event.date.end.day
        ) {
          loadedParticleConfig = value.url;
          break;
        }
        break;
      }
    }

    if (loadedParticleConfig) {
      window.particlesJS.load(
        "particles-js",
        loadedParticleConfig,
        function () {
          console.log("Particles loaded");
        }
      );
    }
  });

import "highlightjs/styles/obsidian.css";
marked.setOptions({
  highlight: (code) => {
    return hljs.highlightAuto(code).value;
  },
});

Vue.config.productionTip = false;

// Vue.use(VueSocialSharing);
// Vue.use(VueBraintree);
Vue.use(VueAxios, axios);
Vue.use(VueMeta);

Vue.mixin({
  data: () => ({
    cdn_url: process.env.VUE_APP_CDN_HOST,
    profile: {},
    projects: [],
  }),
  methods: {
    deleteLocal(key) {
      localStorage.removeItem(key);
    },
    setLocal(
      key,
      value,
      options = {
        expires: 60 * 60 * 24 * 7 * 4,
        canExpire: true,
      }
    ) {
      options.expires = new Date().getTime() + options.expires * 1000;
      if (!options.canExpire) {
        delete options.expires;
      }

      const data = {
        value,
        options,
      };

      localStorage.setItem(key, JSON.stringify(data));
    },
    getLocal(key) {
      let data = localStorage.getItem(key);
      if (!data) return null;

      data = JSON.parse(data);

      if (
        data.options.canExpire &&
        data.options.expires < new Date().getTime()
      ) {
        this.removeLocal(key);
        return null;
      }

      return data.value;
    },
    removeLocal(key) {
      localStorage.removeItem(key);
    },
    isMobileDevice() {
      return (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          window.navigator.userAgent
        ) || this.$vuetify.breakpoint.mobile
      );
    },
    parseMarkdown: (md) => marked.parse(md),
    hoursToTime(hours) {
      // Convert hours to time, e.g. 1672.07 hours = 0 years, 0 months, 1 week, 2 days, 2 hours, 7 minutes, 7 seconds
      let time = "";
      const years = Math.floor(hours / 8760);
      const months = Math.floor((hours % 8760) / 720);
      const weeks = Math.floor(hours / (7 * 24));
      const days = Math.floor((hours % (7 * 24)) / 24);
      const hours_ = Math.floor((hours % (7 * 24)) % 24);
      const minutes = Math.floor((hours % 1) * 60);
      // let seconds = Math.floor((minutes % 1) * 60);

      const timeArray = [];
      const wordedTime = ["year", "month", "week", "day", "hour", "minute"];

      timeArray.push(years);
      timeArray.push(months);
      timeArray.push(weeks);
      timeArray.push(days);
      timeArray.push(hours_);
      timeArray.push(minutes);

      // Format timeArray to be readable
      for (let i = 0; i < timeArray.length; i++) {
        if (timeArray[i] > 0) {
          let splitter = "";
          // Splitter should be "," up to the last 2 elements, and "and" after that
          if (i < timeArray.length - 2) {
            splitter = ", ";
          } else if (i === timeArray.length - 2) {
            splitter = " and ";
          }

          const word = timeArray[i] > 1 ? wordedTime[i] + "s" : wordedTime[i];

          // If i == 1 (Months), then don't include the rest of the array into the string
          if (i == 1) {
            time += `${timeArray[i]} ${word}`;
            break;
          } else {
            time += `${timeArray[i]} ${word}`;
          }

          time += splitter;
        }
      }

      return time;
    },
  },
});

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

new Vue({
  router,
  store,
  vuetify,
  firebase,
  created() {
    // Load profile
    if (this.getLocal("profile")) {
      this.profile = this.getLocal("profile");
    } else {
      fetch(process.env.VUE_APP_API_HOST + "/v3/connections/github/profile")
        .then((gh_res) => gh_res.json())
        .then((github_profile) => {
          this.profile = github_profile;
          return fetch(
            `https://raw.githubusercontent.com/${github_profile.login}/${github_profile.login}/main/WEBSITE`
          );
        })
        .then((ghrepo_res) => ghrepo_res.json())
        .then((gitrepo_profile) => {
          gitrepo_profile.bio = this.profile.bio;
          gitrepo_profile.name = this.profile.name;

          this.setLocal("profile", gitrepo_profile);
          this.profile = gitrepo_profile;
        });
    }

    if (this.getLocal("projects")) {
      this.projects = this.getLocal("projects");
    } else {
      this.axios
        .get(process.env.VUE_APP_API_HOST + "/v3/projects")
        .then((response) => {
          if (response.status == 200) return response.data;
          else throw new Error("Error fetching projects");
        })
        .then((data) => {
          let localStorageData = data;
          localStorageData = localStorageData.filter(
            (project) => project.meta.show
          );

          localStorageData.sort((a, b) => {
            return a.order - b.order;
          });

          this.projects = localStorageData;
          this.setLocal("projects", this.projects);
        })
        .catch(() => {
          this.projects = null;
        });
    }
  },
  render: (h) => h(App),
}).$mount("#app");
