<template>
  <div style="background: black">
    <v-system-bar v-if="authorized" height="36">
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon to="/admin/dashboard" v-bind="attrs" v-on="on">
            <v-icon>bi-shop</v-icon>
          </v-btn>
        </template>
        <span>Go to dashboard</span>
      </v-tooltip>
      <v-card-text>Hey, {{ auth.currentUser.displayName }}!</v-card-text>
      <v-spacer />
      <v-tooltip bottom color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon @click.prevent="signOut" v-bind="attrs" v-on="on">
            <v-icon right>bi-box-arrow-in-left</v-icon>
          </v-btn>
        </template>
        <span>Sign out</span>
      </v-tooltip>
    </v-system-bar>
    <profile-view></profile-view>
    <v-tabs
      v-model="tab"
      centered
      color="white"
      background-color="black"
      :hide-slider="!validPage()"
      style="max-height: 48px"
    >
      <v-tabs-slider color="white"></v-tabs-slider>
      <v-tab
        v-for="page in pages"
        :key="`${page.name.toLowerCase()}_page-tab`"
        :to="page.path"
        :ripple="false"
      >
        <span :class="{ 'white--text': inURL(page) }">{{ page.name }}</span>
      </v-tab>
    </v-tabs>
    <v-main style="background-color: black">
      <v-container
        fluid
        style="
          max-width: 100vw;
          padding: 0px;
          margin: 0px;
          background-color: black;
        "
      >
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
  </div>
</template>

<script>
// import RecentGames from "@/components/RecentGames.vue";
import ProfileView from "@/components/Profile.vue";

// Import firebase auth
import { methods } from "../router/guards";
import { app } from "../plugins/firebase";
import { getAuth, signOut } from "firebase/auth";

const auth = getAuth(app);

export default {
  name: "App",

  components: {
    // RecentGames,
    ProfileView,
  },
  data: () => ({
    profile: [],
    games: [],
    pages: [
      {
        name: "Showcase",
        path: "/",
      },
      {
        name: "Blog",
        path: "/blog",
      },
    ],
    header_img: "",
    tab: 0,
    authorized: false,
    auth,
  }),
  methods: {
    validPage() {
      // Check if current route is in routes
      return this.$route.matched.length > 0;
    },
    inNavbar() {
      // Check if current route's path is in pages
      return this.pages.some((page) => {
        return page.path === this.$route.path;
      });
    },
    inURL(page) {
      return this.$route.path.includes(page.path);
    },
    async authenticated() {
      const result = await methods.authenticate();
      return result == 200;
    },
    signOut() {
      signOut(auth).then(() => (this.authorized = false));
    },
  },
  metaInfo() {
    return {
      titleTemplate: "Bitheral | %s",
      meta: [
        {
          name: "description",
          content: this.$root.profile ? this.$root.profile.bio : "",
        },
      ],
    };
  },
  async mounted() {
    // If the route path contains "project", set this.tabs to 0
    if (this.$route.path.includes("project")) {
      this.tab = 0;
    }

    // If the route path contains "blog", set this.tabs to 1
    if (this.$route.path.includes("blog")) {
      this.tab = 1;
    }

    this.authorized = (await methods.authenticate()).code == 200;
  },
};
</script>
