<template>
  <div>
    <div v-if="post != null">
      <v-row no-gutters align="center" justify="center">
        <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
        <v-col style="min-width: 45%">
          <v-img
            :class="{ 'my-6': !$vuetify.breakpoint.mobile }"
            :ref="'post_header'"
            :src="getImage(post.slug)"
            contain
            @error="noImage()"
            :height="isMobileDevice() ? '256px' : '194px'"
          ></v-img>
          <v-card
            color="black"
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0"
            elevation="12"
            :class="{ 'mb-12': !$vuetify.breakpoint.mobile }"
          >
            <v-list-item
              :two-line="!post.updatedAt"
              :three-line-line="post.updatedAt"
            >
              <v-list-item-content>
                <v-list-item-title class="text-h3">{{
                  post.title
                }}</v-list-item-title>
                <v-list-item-subtitle class="text-h6 pb-1"
                  >Posted on
                  {{ formatDate(post.createdAt) }}</v-list-item-subtitle
                >
                <v-list-item-subtitle class="text-h6" v-if="post.updatedAt">
                  <span>Last updated on </span>
                  <span v-if="sameDate(post.createdAt, post.updatedAt)">{{
                    formatDateTime(post.updatedAt)
                  }}</span>
                  <span v-else>{{ formatDate(post.updatedAt) }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-text
              class="text-body-1 white--text"
              v-html="post.body.html || parseMarkdown(post.body.md)"
            >
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mobile"></v-col>
      </v-row>
    </div>
    <div v-else>
      <v-container fluid>
        <!-- Justify and align center -->
        <v-row justify="center" align="center" style="min-height: 60vh">
          <v-col cols="4" justify="center" align="center">
            <v-card class="elevation-0 transparent">
              <v-card-text
                class="text-center white--text"
                style="user-select: none"
              >
                <h1 class="text-h1 pb-12">¯\_(ツ)_/¯</h1>
                <h5 class="text-h5">{{ blog_err.title }}</h5>
                <h6 class="text-h6">{{ blog_err.message }}</h6>
                <v-spacer></v-spacer>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
// import PayPal from "vue-paypal-checkout";

export default {
  name: "PostView",
  data() {
    return {
      post: {},
      loading: true,
      images: {},
      blog_err: {},
    };
  },
  metaInfo() {
    return {
      title: this.post
        ? this.post.title
          ? this.post.title
          : "Loading..."
        : "Post not found",
    };
  },
  methods: {
    getImage(slug) {
      return `https://cdn.bitheral.net/blog/${slug}.png`;
    },
    noImage() {
      this.$refs["post_header"][0].src =
        "https://cdn.bitheral.net/static_www/img/404.png";
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    formatTime(date) {
      let timeStr = new Date(date).toLocaleTimeString();

      timeStr = timeStr.split(":")[0] + ":" + timeStr.split(":")[1];
      return timeStr;
    },
    formatDateTime(date) {
      return this.formatDate(date) + ", at " + this.formatTime(date);
    },
    sameDate(date1, date2) {
      return new Date(date1) === new Date(date2);
    },
  },
  async created() {
    const post_slug = this.$route.params.slug;

    const seenBlogposts = this.getLocal("seen_blogposts") || [];
    const hasSeen = seenBlogposts.includes(post_slug);

    const headers = {
      "Content-Type": "application/json",
      "X-Has-Seen": hasSeen,
    };

    this.axios
      .get(`${process.env.VUE_APP_API_HOST}/v3/blog/post?slug=${post_slug}`, {
        headers: headers,
      })
      .then((response) => {
        if (response.status && response.status == 200) return response.data;
        else throw new Error("¯\\_(ツ)_/¯");
      })
      .then((data) => {
        // Check if seenBlogposts is an empty array
        if (seenBlogposts.length == 0) {
          seenBlogposts.push(post_slug);
          this.setLocal("seen_blogposts", seenBlogposts, {
            expires: 0,
            canExpire: false,
          });
        } else {
          if (!hasSeen) {
            seenBlogposts.push(post_slug);
            this.setLocal("seen_blogposts", seenBlogposts, {
              expires: 0,
              canExpire: false,
            });
          }
        }

        this.post = data;
        this.images = this.getImage(this.post.slug);
        this.loading = false;
      })
      .catch(() => {
        this.blog_err = {
          title: "Whoops! That post doesn't exist.",
          message: "",
        };
        this.post = null;
        this.loading = false;
      });
  },
};
</script>

<style></style>
