<template>
  <div>
    <div v-if="itemType == 'project'">
      <v-card>
        <v-card-title><h1>Add a new project</h1></v-card-title>
        <v-form @submit.prevent="submitForm">
          <v-container class="px-6">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="slug"
                  label="Slug"
                  :readonly="!customSlug.enabled"
                  :disabled="!customSlug.enabled"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-switch
                  v-model="customSlug.enabled"
                  label="Custom slug"
                  :ripple="false"
                ></v-switch>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="item.name"
                  label="Name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <h2>About {{ item.name ? item.name : "the project" }}</h2>
                <vue-easymde
                  :highlight="true"
                  preview-class="markdown-body"
                  v-model="item.body.md"
                  ref="editor"
                ></vue-easymde>
              </v-col>
              <v-col cols="3">
                <h2>Tags</h2>
                <v-text-field
                  v-model="newTag"
                  placeholder="Add a tag"
                  @keypress="handleKeyForTag"
                  hint="Press comma to add a tag"
                ></v-text-field>
              </v-col>
              <v-col cols="auto">
                <v-chip-group v-if="item.tags">
                  <v-chip
                    v-for="(tag, index) in item.tags"
                    :key="`tag_${index}`"
                    close
                    @click:close="item.tags.splice(index, 1)"
                    :ripple="false"
                    outlined
                    color="white"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
              </v-col>
              <v-col cols="12">
                <h2>Downloads</h2>
                <v-btn @click.prevent="addDownload()">
                  <v-icon>bi-plus</v-icon>
                  <span>Add download</span>
                </v-btn>

                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(download, dIndex) in item.downloads"
                    :key="`download_${dIndex}`"
                  >
                    <v-expansion-panel-header>
                      {{ download.name || "Version" }}
                      {{ download.version ? `v${download.version}` : "v0.0.1" }}
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        icon
                        tile
                        @click.prevent="removeDownload(dIndex)"
                        max-width="32px"
                        class="mr-2"
                      >
                        <v-icon>bi-trash</v-icon>
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="download.name"
                            placeholder="Download name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="download.version"
                            label="Version"
                            :placeholder="download.version || 'v0.0.1'"
                            required
                            :rules="rules.semantic"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-tabs>
                            <v-tab
                              v-for="(platform, pIndex) in download.platforms"
                              :key="`${dIndex}_${pIndex}_${platform}`"
                            >
                              <v-spacer></v-spacer>
                              {{ platform.platform }}
                              <v-spacer></v-spacer>
                              <v-btn
                                tile
                                outlined
                                icon
                                color="error"
                                @click.prevent="removePlatform(dIndex, pIndex)"
                              >
                                <v-icon>bi-trash</v-icon>
                              </v-btn>
                            </v-tab>
                            <v-btn
                              v-for="platform in Object.keys(platforms).filter(
                                (platform) => {
                                  return !downloadHasPlatform(dIndex, platform);
                                }
                              )"
                              large
                              icon
                              tile
                              outlined
                              :key="`${dIndex}_${platform}
                              }`"
                              @click.prevent="addPlatform(dIndex, platform)"
                              class="mx-1"
                            >
                              <v-icon size="24px"
                                >bi-{{ platformIcons[platform] }}</v-icon
                              >
                            </v-btn>
                            <v-tab-item
                              v-for="(plat, p) in download.platforms"
                              :key="`tab_content_${p}`"
                            >
                              <v-row>
                                <v-col cols="2">
                                  <v-select
                                    v-model="plat.download_checksum.name"
                                    :items="checksumTypes"
                                    label="Cheksum method"
                                  ></v-select>
                                </v-col>
                                <v-col>
                                  <v-text-field
                                    v-model="plat.download_checksum.checksum"
                                    label="Checksum"
                                  ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="plat.download_url"
                                    label="Download URL"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                            </v-tab-item>
                          </v-tabs>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12">
                <h2>Collaborators</h2>
                <v-btn @click.prevent="addCollaborator()">
                  <v-icon>bi-plus</v-icon>
                  <span>Add collaborator</span>
                </v-btn>

                <v-expansion-panels>
                  <v-expansion-panel
                    v-for="(collaborator, cIndex) in item.collaborators"
                    :key="`collaborator_${cIndex}`"
                  >
                    <v-expansion-panel-header
                      >{{
                        collaborator.name
                          ? collaborator.name
                          : `Collaborator #${cIndex + 1}`
                      }}
                      <v-spacer></v-spacer>
                      <v-btn
                        color="error"
                        outlined
                        icon
                        tile
                        @click.prevent="removeCollaborator(cIndex)"
                        max-width="32px"
                        class="mr-2"
                      >
                        <v-icon>bi-trash</v-icon>
                      </v-btn>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="collaborator.name"
                            label="Name"
                            required
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3">
                          <v-text-field
                            v-model="newRole"
                            label="Roles"
                            @keypress="handleKeyForRole($event, cIndex)"
                            hint="Press comma to add a role"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="auto">
                          <v-chip
                            v-for="(role, rIndex) in collaborator.roles"
                            :key="role"
                            close
                            @click:close="removeRole(cIndex, rIndex)"
                            class="mx-2"
                            :ripple="false"
                            outlined
                            color="white"
                          >
                            {{ role }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-col>
              <v-col cols="12">
                <v-file-input
                  v-model="item.screenshots"
                  label="Screenshots"
                  multiple
                ></v-file-input>
              </v-col>
            </v-row>
            <v-footer color="transparent">
              <v-btn type="submit" color="primary">Add</v-btn>
              <v-spacer></v-spacer>
              <v-checkbox
                :ripple="false"
                v-model="displayItem"
                label="Display on showcase"
                color="primary"
              ></v-checkbox>
            </v-footer>
          </v-container>
        </v-form>
      </v-card>
    </div>
    <div v-else-if="itemType == 'blog'">
      <v-card>
        <v-card-title class="py-6"
          ><h1>Create a new blog post</h1></v-card-title
        >
        <v-form @submit.prevent="submitForm">
          <v-container class="px-6">
            <v-row>
              <v-col cols="8">
                <v-text-field
                  v-model="slug"
                  label="Slug"
                  :readonly="!customSlug.enabled"
                  :disabled="!customSlug.enabled"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-switch
                  v-model="customSlug.enabled"
                  label="Custom slug"
                  :ripple="false"
                ></v-switch>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="item.title"
                  label="Post title"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <h2>Post blurb</h2>
                <vue-easymde
                  :highlight="true"
                  preview-class="markdown-body"
                  v-model="item.blurb.md"
                  ref="blurbEditor"
                ></vue-easymde>
              </v-col>
              <v-col cols="12">
                <h2>Post body</h2>
                <vue-easymde
                  :highlight="true"
                  preview-class="markdown-body"
                  v-model="item.body.md"
                  ref="bodyEditor"
                ></vue-easymde>
              </v-col>
            </v-row>
            <v-footer color="transparent">
              <v-btn type="submit" color="primary">Add</v-btn>
              <v-spacer></v-spacer>
              <v-checkbox
                :ripple="false"
                v-model="displayItem"
                label="Display on blog"
                color="primary"
              ></v-checkbox>
            </v-footer>
          </v-container>
        </v-form>
      </v-card>
    </div>
  </div>
</template>

<style scoped>
/* @import "~@bitheral/easymde/dist/easymde.min.css"; */
@import "~@/assets/easymde.bith.css";
@import "~github-markdown-css";
@import "codemirror/theme/3024-night.css";
@import "highlightjs/styles/obsidian.css";
</style>

<script>
import hljs from "highlightjs";
import VueEasymde from "@bitheral/vue-easymde";

import axiosInstance from "@/plugins/axios";

window.hljs = hljs;

export default {
  components: {
    VueEasymde,
  },
  props: {
    data: {
      type: Object,
    },
  },
  data() {
    return {
      customSlug: {
        enabled: false,
        value: "",
      },
      item: {},
      itemType: "",
      newTag: "",
      newRole: "",

      rules: {
        semantic: [
          (v) => !!v || "A version must be provided",
          (v) =>
            /^(0|[1-9]\d*)\.(0|[1-9]\d*)\.(0|[1-9]\d*)(?:-((?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*)(?:\.(?:0|[1-9]\d*|\d*[a-zA-Z-][0-9a-zA-Z-]*))*))?(?:\+([0-9a-zA-Z-]+(?:\.[0-9a-zA-Z-]+)*))?$/.test(
              v
            ) || "Invalid semantic",
        ],
      },

      platforms: {
        windows: "Windows",
        mac: "Mac",
        linux: "Linux",
      },
      platformIcons: {
        windows: "windows",
        mac: "apple",
        linux: "ubuntu",
      },

      checksumTypes: ["MD5", "SHA1", "SHA256"],
    };
  },
  created() {
    this.item = Object.assign({}, this.data);
    this.itemType = this.item.type;
  },
  methods: {
    addTag() {
      if (this.newTag) {
        if (this.item.tags.includes(this.newTag)) {
          return;
        }

        this.item.tags.push(this.newTag);
        this.newTag = "";
      }
    },

    addDownload() {
      const downloadTemplate = {
        name: "",
        version: "",
        platforms: [],
        notes: "",
      };

      this.item.downloads.unshift(downloadTemplate);
    },
    removeDownload(index) {
      this.item.downloads.splice(index, 1);
    },

    addPlatform(index, platform) {
      const platformTemplate = {
        download_checksum: {
          name: "",
          checksum: "",
        },
        platform,
        download_url: "",
      };

      this.item.downloads[index].platforms.push(platformTemplate);
    },
    removePlatform(dIndex, pIndex) {
      this.item.downloads[dIndex].platforms.splice(pIndex, 1);
    },
    checkForPlatform(dindex, platform_key) {
      return this.item.downloads[dindex].platforms.forEach((platform) => {
        return platform.platform == platform_key;
      });
    },
    downloadHasPlatform(dIndex, platform) {
      return this.item.downloads[dIndex].platforms.some(
        (p) => p.platform == platform
      );
    },

    addCollaborator() {
      const collaboratorTemplate = {
        name: "",
        roles: [],
      };

      this.item.collaborators.unshift(collaboratorTemplate);
    },
    removeCollaborator(index) {
      this.item.collaborators.splice(index, 1);
    },

    addRole(cIndex) {
      if (this.newRole) {
        if (this.item.collaborators[cIndex].roles.includes(this.newRole)) {
          return;
        }

        this.item.collaborators[cIndex].roles.push(this.newRole);
        this.newRole = "";
      }
    },
    removeRole(cIndex, rIndex) {
      this.item.collaborators[cIndex].roles.splice(rIndex, 1);
    },

    handleKeyForTag(event) {
      if (event.charCode == 44) {
        this.addTag();
        setTimeout(() => {
          this.newTag = "";
        }, 0);
      }
    },
    handleKeyForRole(event, cIndex) {
      if (event.charCode == 44) {
        this.addRole(cIndex);
        setTimeout(() => {
          this.newRole = "";
        }, 0);
      }
    },
    async submitForm() {
      const itemType = this.itemType;

      if (itemType == "project") {
        this.item.body.html = this.parseMarkdown(this.item.body.md);
      } else if (itemType == "blog") {
        this.item.blurb.html = this.parseMarkdown(this.item.blurb.md);
        this.item.body.html = this.parseMarkdown(this.item.body.md);
      }

      const endpoint = {
        project: "/v3/projects/add",
        blog: "/v3/blog/create",
      };

      const nAxiosInstance = await axiosInstance();
      if (this.customSlug.enabled) {
        this.item.slug = this.customSlug.value;
      } else {
        this.item.slug = this.slugify(this.item.name || this.item.title);
      }

      nAxiosInstance.post(endpoint[itemType], this.item);

      setTimeout(() => {
        this.$router.go();
      }, 1000);
    },
    slugify(str) {
      return (
        str
          .normalize("NFKD")
          .replace(/[\u0300-\u036F]/g, "")
          .replace(/[^\w\s-]/g, "")
          .toLowerCase()
          .trim()
          .replace(/[-\s]+/g, "-") || ""
      );
    },
  },
  computed: {
    // slugify() {
    //   let name = this.itemType == "blog" ? this.item.title : this.item.name;
    //   return (
    //     name
    //       .normalize("NFKD")
    //       .replace(/[\u0300-\u036F]/g, "")
    //       .replace(/[^\w\s-]/g, "")
    //       .toLowerCase()
    //       .trim()
    //       .replace(/[-\s]+/g, "-") || ""
    //   );
    // },
    slug: {
      get() {
        if (this.customSlug.enabled) {
          return this.customSlug.value;
        } else {
          const name =
            this.itemType == "blog" ? this.item.title : this.item.name;
          return name
            .normalize("NFKD")
            .replace(/[\u0300-\u036F]/g, "")
            .replace(/[^\w\s-]/g, "")
            .toLowerCase()
            .trim()
            .replace(/[-\s]+/g, "-");
        }
      },
      set(value) {
        if (this.customSlug.enabled) this.customSlug.value = value;
        else this.item.slug = value;
      },
    },
    displayItem: {
      get() {
        return this.item.meta.show || !this.item.meta.hidden;
      },
      set(value) {
        this.item.meta.show = value;
        this.item.meta.hidden = !value;
      },
    },
  },
};
</script>

<style></style>
