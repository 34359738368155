import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { app } from "../plugins/firebase";
import axios from "axios";

const auth = getAuth(app);
export const methods = {
  authenticate: () => {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user
            .getIdToken()
            .then((token) =>
              axios({
                method: "post",
                url: process.env.VUE_APP_API_HOST + "/v3/auth",
                data: {
                  token,
                },
              })
            )
            .then((response) => resolve({ code: response.status }))
            .catch(({ response }) => resolve({ code: response.status }));
        } else {
          resolve({ code: 500 });
        }
      });
    });
  },
};

export const guards = {
  auth: async (to, from, next) => {
    switch ((await methods.authenticate()).code) {
      case 200:
        next();
        break;
      case 500:
        next({ path: "/admin/auth" });
        break;
      default:
        signOut(auth);
        next({ path: "/" });
        break;
    }
  },
};

export default guards;
