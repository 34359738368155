<template>
  <div>
    <div v-if="!loading" class="d-flex flex-wrap">
      <v-container class="ma-0 pa-0" fluid>
        <v-row no-gutters>
          <v-col
            v-for="project in $root.projects"
            cols="12"
            sm="6"
            md="6"
            lg="3"
            :key="project.slug"
          >
            <v-card
              max-height="512px"
              tile
              :to="`/project/${project.slug}`"
              color="black"
            >
              <v-responsive aspect-ratio="4/3">
                <v-img
                  :src="getImage(project)"
                  class="white--text align-end"
                  gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                  max-height="512px"
                >
                  <v-card-title class="headline font-weight-light">
                    {{ project.name }}
                  </v-card-title>
                </v-img>
              </v-responsive>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div v-else>
      <v-container style="height: 55.33vh" fluid>
        <v-row
          class="fill-height"
          align="center"
          align-content="center"
          justify="center"
        >
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              color="white"
              size="64"
            ></v-progress-circular>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<style>
.bottom-gradient {
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
</style>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      project_err: {
        title: "Whoops! We couldn't load the projects.",
        message: "Please try again later.",
      },
    };
  },
  methods: {
    getImage(project) {
      return `https://cdn.bitheral.net/projects/${project.slug}/thumbnail.png`;
    },
  },
  metaInfo() {
    return {
      title: "Showcase",
      meta: [
        {
          name: "description",
          content: `Explore my current projects, including ${this.projects
            .slice(0, 4)
            .map((project) => project.name)
            .join(", ")
            .replace(/,(?=[^,]*$)/, " and")}.`,
        },
      ],
    };
  },
  computed: {
    calculateItemSize() {
      // Calculate the width of the card based on the number of columns
      let width = this.$vuetify.breakpoint.width;
      if (width > 1920) {
        width = 1920;
      }

      if (width > 1280) {
        width = width / 4;
      } else if (width > 960) {
        width = width / 3;
      } else if (width > 600) {
        width = width / 2;
      }

      return width;
    },
    loading() {
      return Array.isArray(this.$root.projects) && !this.$root.projects.length;
    },
  },
};
</script>
