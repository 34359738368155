<template>
  <v-card
    class="elevation-0 black pa-0"
    tile
    v-if="$root.profile"
    style="user-select: none"
  >
    <v-img
      :src="`${cdn_url}/renders/Luna/large`"
      :srcset="`
        ${cdn_url}/renders/Luna/xsmall 240w,
				${cdn_url}/renders/Luna/small 480w,
				${cdn_url}/renders/Luna/medium 960w,
      `"
      sizes="(max-width: 600px) 240px,
             (max-width: 960px) 480px,
             3840px"
      alt="Luna"
      position="center center"
      eager
      :lazy-src="`${cdn_url}/renders/Luna/xsmall`"
      max-height="333"
    >
      <v-container fill-height id="prfl-container">
        <v-layout align-center justify-center column class="white--text">
          <v-flex xs12>
            <v-card-text class="text-center">
              <v-avatar size="128" rounded="true">
                <v-img
                  contain
                  :src="`${cdn_url}/static_www/img/logo/128`"
                ></v-img>
              </v-avatar>
              <h1 class="text-h4 pb-0 pt-3">{{ $root.profile.name }}</h1>
              <h2 class="text-h6 pb-3" v-show="$vuetify.breakpoint.mdAndUp">
                {{ $root.profile.bio }}
              </h2>
              <v-row
                dense
                justify="center"
                :class="{
                  'pt-3': $vuetify.breakpoint.smAndDown,
                }"
              >
                <v-col
                  cols="auto"
                  v-for="social in $root.profile.socials"
                  :key="`social_${social.platform.toLowerCase()}`"
                >
                  <v-chip
                    outlined
                    color="white"
                    :href="social.link"
                    target="_blank"
                    rel="nofollow author external"
                    :ripple="false"
                    class="noHighlight"
                    v-show="social.link || $vuetify.breakpoint.smAndUp"
                  >
                    <v-icon
                      class="pl-0 pr-2"
                      :class="social.icon"
                      size="16px"
                    ></v-icon
                    >{{ social.handle }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card-text>
          </v-flex>
        </v-layout>
      </v-container>
      <div id="particles-js"></div>
    </v-img>
  </v-card>
</template>

<script>
export default {
  name: "ProfileView",
  data() {
    return {
      // profile: null,
      tab: null,
    };
  },
};
</script>

<style></style>
