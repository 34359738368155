var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"black"},attrs:{"id":"scrollable"}},[_c('v-row',{staticClass:"py-4",style:(_vm.styles),attrs:{"id":"scrollable","no-gutters":"","align":"center","justify":"center"}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col'):_vm._e(),_c('v-col',{staticStyle:{"min-width":"50%"}},[(!_vm.noheader)?_c('v-img',{staticClass:"my-12",attrs:{"src":_vm.getImages(_vm.item.slug).header,"contain":"","eager":""},on:{"error":function($event){_vm.noheader = true}}}):_c('v-card-text',{staticClass:"text-center text-uppercase py-12",class:{
          'text-h1': !_vm.$vuetify.breakpoint.mobile,
          'text-h3': _vm.$vuetify.breakpoint.mobile,
          'white--text': _vm.item.name,
          'grey--text': _vm.data.name && !_vm.item.name,
        },staticStyle:{"user-select":"none"},style:({
          'font-size: 5rem !important': !_vm.isMobileDevice(),
        })},[_vm._v(" "+_vm._s(_vm.item.name || _vm.data.name)+" ")]),_c('v-card',{staticStyle:{"border-bottom-left-radius":"0","border-bottom-right-radius":"0"},attrs:{"color":"black","elevation":"12"}},[_c('v-btn',{class:{
            error: _vm.item.meta.show,
            success: !_vm.item.meta.show,
          },attrs:{"block":"","tile":""},on:{"click":function($event){$event.preventDefault();_vm.item.meta.show = !_vm.item.meta.show}}},[_vm._v(_vm._s(_vm.item.meta.show ? "Hide" : "Show")+" "+_vm._s(_vm.item.name || _vm.data.name)+" ")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"info"}},[_vm._v("Save changes")])],1),_c('v-col',[_c('v-btn',{attrs:{"ripple":false,"block":"","tile":""},on:{"click":function($event){$event.preventDefault();return _vm.resetChanges()}}},[_vm._v("Cancel changes")])],1)],1),_c('v-row',{staticClass:"py-4",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"px-8",attrs:{"disabled":"","outlined":"","height":"48px","width":"auto","href":_vm.item.meta.svc_url,"target":"_blank","rel":"nofollow author external"}},[_c('v-icon',{staticClass:"bi-github pr-2",attrs:{"left":"","size":"26px"}}),_vm._v(" View project on GitHub ")],1)],1)],1),_c('v-card-text',{staticClass:"text-h3 white--text pb-0"},[_vm._v("About "),_c('v-text-field',{staticClass:"text-h4",staticStyle:{"display":"inline-block"},attrs:{"placeholder":_vm.data.name,"solo":""},model:{value:(_vm.item.name),callback:function ($$v) {_vm.$set(_vm.item, "name", $$v)},expression:"item.name"}})],1),_c('vue-easymde',{ref:"editor",staticClass:"mx-4",attrs:{"highlight":true,"preview-class":"markdown-body"},model:{value:(_vm.item.body.md),callback:function ($$v) {_vm.$set(_vm.item.body, "md", $$v)},expression:"item.body.md"}}),_c('div',[_c('v-card-text',{staticClass:"text-h3 white--text"},[_vm._v("Screenshots")]),(_vm.item.screenshots.length != 0)?_c('v-row',{staticClass:"px-4",attrs:{"no-gutters":""}},_vm._l((9),function(n){return _c('v-col',{key:`${_vm.item.slug}_screenshot_${n}`,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[(n - 1 < _vm.item.screenshots.length)?_c('v-img',{key:`${_vm.item.slug}_screenshot-${n - 1}`,attrs:{"src":`https://cdn.bitheral.net/projects/${
                  _vm.item.slug
                }/screenshots/${_vm.item.slug}-${n - 1}.png`,"lazy-src":`https://cdn.bitheral.net/projects/${
                  _vm.item.slug
                }/screenshots/${_vm.item.slug}-${n - 1}.png`,"aspect-ratio":"1","height":"100%"}}):_c('v-card',{attrs:{"tile":"","height":"233.417"},on:{"click":function($event){$event.preventDefault();}}},[_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"64px"}},[_vm._v("bi-plus")])],1)],1)],1)],1)}),1):(
              _vm.item.screenshots == null || _vm.item.screenshots.length == 0
            )?_c('v-row',{staticClass:"px-4",attrs:{"no-gutters":""}},_vm._l((9),function(n){return _c('v-col',{key:n,staticClass:"d-flex child-flex",attrs:{"cols":"4"}},[_c('v-card',{attrs:{"height":"150"}},[_c('v-card-text',{staticClass:"text-center"},[_c('v-icon',{attrs:{"size":"64px"}},[_vm._v("bi-image")])],1)],1)],1)}),1):_vm._e()],1),_c('div',[_c('v-card-text',{staticClass:"text-h3 white--text pb-0"},[_vm._v("Collaborators")]),(_vm.item.collaborators.length != 0)?_c('div',_vm._l((_vm.item.collaborators),function(contributor){return _c('v-list-item',{key:`${
                _vm.item.slug
              }_contributor-${contributor.name.toLowerCase()}`,staticClass:"mx-1",attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-text-field',{attrs:{"value":contributor.name}}),_c('v-chip-group',{attrs:{"column":""}},[_vm._l((contributor.roles),function(role){return _c('v-chip',{key:role},[_vm._v(" "+_vm._s(role)+" ")])}),_c('v-text-field',{attrs:{"placeholder":`Add a role for ${contributor.name}`}})],2)],1)],1)}),1):_c('div',{staticClass:"mx-4"},[_c('v-btn',[_vm._v(" Add a collaborator ")])],1)],1),(_vm.item.downloads.length != 0 && !_vm.isMobileDevice())?_c('div',[_c('v-card-text',{staticClass:"text-h3 white--text pb-0"},[_vm._v("Downloads")]),_vm._l((_vm.item.downloads
              .filter((dl) =>
                dl.platforms.find((plat) => plat.download_url != '')
              )
              .slice(0, 6)),function(download,download_index){return _c('div',{key:`${download.name}_v${download.version}`},[_c('v-card',{staticClass:"black mx-4 mt-2"},[_c('v-card-text',{staticClass:"px-0 pt-0 white--text",class:{ 'pb-2': download.notes }},[(download_index == 0)?_c('span',{staticClass:"text-h4"},[_vm._v(" Latest download - v"+_vm._s(download.version)+" ")]):_c('span',{staticClass:"text-h5"},[_vm._v(" v"+_vm._s(download.version)+" ")])]),(download.notes)?_c('v-card-text',{staticClass:"px-0 pt-0"},[_c('em',[_c('strong',{staticClass:"white--text"},[_vm._v("NOTE:")]),_vm._v(" "+_vm._s(download.notes))])]):_vm._e(),_c('v-row',_vm._l((download.platforms),function(platform,pIndex){return _c('v-col',{key:`${_vm.data.slug.toLowerCase()}_download-${
                    download.version
                  }-${platform.platform}`,attrs:{"cols":"4"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"info"}},[_vm._v("Edit")])],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","tile":"","color":"error"},on:{"click":function($event){$event.preventDefault();return _vm.deleteDownload(download_index, pIndex)}}},[_vm._v("Remove")])],1)],1),_c('v-btn',{attrs:{"disabled":"","tile":"","block":"","x-large":"","href":platform.download_url,"outlined":platform.platform == _vm.getPlatform && download_index == 0}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.getAppropriateIcon(platform.platform)))]),_vm._v(" Download ")],1),_c('v-card-text',{staticClass:"pa-0 pt-2 text-subtitle-1"},[_vm._v("Checksum")]),_c('v-card-text',{staticClass:"text-subtitle-2 pa-0"},[_vm._v(" "+_vm._s(platform.download_checksum.name)+": "+_vm._s(platform.download_checksum.checksum)+" ")])],1)}),1)],1)],1)})],2):_vm._e()],1)],1),(!_vm.$vuetify.breakpoint.mobile)?_c('v-col'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }